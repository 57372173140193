body {
  font-family: "Ubuntu", sans-serif;
}

.kalam-light {
  font-family: "Kalam", cursive;
  font-weight: 300;
  font-style: normal;
}

.kalam-regular {
  font-family: "Kalam", cursive;
  font-weight: 400;
  font-style: normal;
}

.kalam-bold {
  font-family: "Kalam", cursive;
  font-weight: 700;
  font-style: normal;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

body {
  box-sizing: border-box;
  height: 100svh;
  margin: 0;
  display: flex;
  padding: 0 10px;
  background: url("images/background-image-large.webp") no-repeat;
  background-size: cover;
  color: #153314;
  position: relative;
}

@media (max-width: 768px) {
  body {
    display: flex;
    align-items: center;
  }
}
button, input[type=button] {
  font-weight: 700;
  border-radius: 20px;
}

.MuiDataGrid-scrollbar {
  scrollbar-width: none;
}

.MuiDataGrid-row {
  font-weight: 400;
}

.MuiDataGrid-row:hover {
  font-weight: 600;
  cursor: pointer;
  border-radius: 20px;
  border: solid grey 1px;
}

.input-field, .register-form input:not([type=submit]), .login-form input:not([type=submit]), .login-form-submit-input {
  border: inset 2px #153314;
  background-color: rgba(154, 158, 158, 0.8);
  width: 20rem;
  font-weight: bold;
  border-radius: 20px;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-aliment-modal-box {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 20px;
  background: rgba(207, 238, 182, 0.1411764706);
  backdrop-filter: blur(6px);
  color: #020202;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
}
.show-aliment-modal-box li {
  list-style-type: none;
  font-weight: 600;
}

.show-meal-dialog > div > div {
  border-radius: 20px;
  box-shadow: rgba(5, 5, 5, 0.85) 0.2rem 0.2rem 0.5rem 2px;
  background: #c1bbbb;
}

.show-meal-dialog__dialog-content {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
}

.showMeal-dialog__buttons-container {
  display: flex;
  flex-direction: column;
}
.showMeal-dialog__buttons-container > button {
  border-radius: 8px;
  margin: 0.5rem 0.5rem;
}

.navbar {
  display: flex;
  flex-direction: row-reverse;
}
.navbar .navbar__link {
  text-decoration: none;
  font-weight: bolder;
  padding: 0.5rem;
  color: #153314;
}
.navbar .navbar__link:hover {
  box-shadow: inset 0 -2px 0 rgb(39, 99, 38);
  color: rgb(39, 99, 38);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.navbar .navbar__button {
  color: rgba(25, 25, 106, 0.88);
}
.navbar .navbar__button:hover {
  color: #4e1b2d;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column-reverse;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -10;
    background: #4e1b2d;
    border-radius: 0 20px 20px 0;
  }
  .navbar .navbar__link {
    color: rgb(39, 99, 38);
  }
}
.profile-carousel {
  height: 90%;
  max-height: none;
  display: flex;
}
.profile-carousel h2 {
  margin-left: 5%;
}

.profile-user-infos, .profile-customization {
  margin: 2%;
}

.user-infos-details {
  display: flex;
  flex-direction: column;
  margin: 5%;
}
.user-infos-details div {
  display: flex;
}

h1 {
  text-align: center;
}

.pageContent {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  background: rgba(103, 193, 227, 0.568627451);
  height: 90svh;
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  padding: 4rem;
  overflow-x: hidden;
  overflow-y: scroll;
  backdrop-filter: blur(0.15rem);
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.pageContent fieldset {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.pageContent .aliments-list__list-item {
  list-style: none;
}
.pageContent .aliments-list__list-item:hover {
  font-weight: bolder;
  cursor: pointer;
  border-radius: 20px;
  border: solid grey 1px;
  background-color: rgba(215, 231, 231, 0.2);
  padding: 0 0.8rem;
  margin-left: -0.8rem;
}

@media (max-width: 768px) {
  .pageContent {
    padding: 1rem;
    margin: 0.5rem;
  }
}
.alphabeticPagination {
  display: flex;
  justify-content: center;
}

.alphabeticPagination-button > span {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
}

.alcalin {
  color: #153314;
}

.acid {
  color: #4e1b2d;
}

@media (max-width: 768px) {
  .alphabeticPagination > div {
    flex-wrap: nowrap;
    overflow: scroll;
    justify-content: flex-start;
    padding: 2rem;
    scrollbar-width: none;
  }
}
.calendar-container {
  height: 100%;
}
.calendar-container > div {
  height: 100%;
}
.calendar-container tr, .calendar-container td {
  border: 0;
}
.calendar-container .fc-scroller {
  scrollbar-width: none;
}

div.autocomplete-textfield > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fc-daygrid-day-frame {
  display: flex;
}
.fc-daygrid-day-frame .fc-daygrid-day-events {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-register-forms-container {
  margin-top: 10%;
  align-items: center;
}
.login-register-forms-container > div {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.login-register-forms-container .register-form-container {
  justify-content: center;
}
.login-register-forms-container .login-form-container {
  justify-content: space-around;
}
.login-register-forms-container .login-register-toggle-buttons-container {
  width: 100%;
}
.login-register-forms-container .login-register-toggle-buttons-container button {
  width: 50%;
}

.register-form, .login-form {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 20px;
  background: rgba(207, 238, 182, 0.1411764706);
  backdrop-filter: blur(6px);
  color: #020202;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
}
.register-form ~ div, .login-form ~ div {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.error-message {
  color: #4e1b2d;
  font-weight: bold;
}

@media (max-width: 768px) {
  .login-register-forms-container {
    margin: 0;
  }
}
.notfoundpage__animated-logo-container {
  padding: 3rem;
}

@media (max-width: 768px) {
  .notfoundpage__animated-logo-container {
    padding: 3rem 0;
  }
}
.recipes-sections-container {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 1rem;
  border-radius: 20px;
}

.add-edit-recipe-form-container {
  grid-row: 1/3;
}

.subcategory-select-input-container, .category-select-input-container {
  width: 100%;
}
.subcategory-select-input-container > div, .category-select-input-container > div {
  width: 100%;
}

form .recipe-title-input {
  margin-bottom: 1rem;
}

div.recipe-title:hover {
  cursor: pointer;
  color: rgb(39, 99, 38);
  font-weight: bolder;
}

.recipes-extract {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  border-radius: 20px;
  padding: 1rem;
}
.recipes-extract li {
  list-style-type: initial;
}
.recipes-extract h2 {
  font-size: 1.2rem;
}
.recipes-extract .recipe-category {
  font-style: italic;
}

.add-edit-recipe-form-container {
  padding: 2rem;
}
.add-edit-recipe-form-container form > div {
  width: 100%;
}
.add-edit-recipe-form-container .category-and-subcategory-selects-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.add-edit-recipe-form-container .category-and-subcategory-selects-container .subcategory-select-input-container, .add-edit-recipe-form-container .category-and-subcategory-selects-container .category-select-input-container {
  display: flex;
  flex-direction: column;
}
.add-edit-recipe-form-container .category-and-subcategory-selects-container .subcategory-select-input-container > div, .add-edit-recipe-form-container .category-and-subcategory-selects-container .category-select-input-container > div {
  margin: 0.2rem;
}

.recipe-aliments-list-and-quantities-container, .event-aliments-list-and-quantities-container {
  display: flex;
}
.recipe-aliments-list-and-quantities-container > .autocomplete-aliment-search-input, .event-aliments-list-and-quantities-container > .autocomplete-aliment-search-input {
  width: 80%;
}
.recipe-aliments-list-and-quantities-container > .autocomplete-aliment-search-input > input #autocomplete-search-input, .event-aliments-list-and-quantities-container > .autocomplete-aliment-search-input > input #autocomplete-search-input {
  width: 100%;
}
.recipe-aliments-list-and-quantities-container .quantities-container, .event-aliments-list-and-quantities-container .quantities-container {
  padding: 9px;
}
.recipe-aliments-list-and-quantities-container .quantities-container .quantities, .event-aliments-list-and-quantities-container .quantities-container .quantities {
  display: flex;
  height: 32px;
  margin: 3px;
}
.recipe-aliments-list-and-quantities-container .quantities-container .quantities-textfield, .event-aliments-list-and-quantities-container .quantities-container .quantities-textfield {
  height: 32px;
}
.recipe-aliments-list-and-quantities-container .quantities-container .quantities-textfield > div, .event-aliments-list-and-quantities-container .quantities-container .quantities-textfield > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8125rem;
  max-width: calc(100% - 6px);
  height: 32px;
  margin: 0;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}
.recipe-aliments-list-and-quantities-container .quantities-container .quantities-textfield fieldset, .event-aliments-list-and-quantities-container .quantities-container .quantities-textfield fieldset {
  height: 32px;
  border: none;
}
.recipe-aliments-list-and-quantities-container .quantities-container .quantities-textfield input, .event-aliments-list-and-quantities-container .quantities-container .quantities-textfield input {
  padding: 3px 0.25px;
  height: 32px;
  width: 4rem;
  text-align: center;
}

.display-customization-checkboxes-formgroup {
  flex-direction: row !important;
}

.display-customization-checkboxes-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.display-customization-checkboxes-form > div {
  width: 80%;
}
.display-customization-checkboxes-form .set-display-customization-input {
  box-shadow: 2px 4px 8px rgba(22, 21, 21, 0.8784313725);
  border-radius: 20px;
  background-color: #85845c;
  border: none;
  margin: 1rem;
  padding: 10px 20px;
}
.display-customization-checkboxes-form .set-display-customization-input:hover {
  background-color: rgba(221, 220, 153, 0.15);
  color: #064206;
}
.display-customization-checkboxes-form svg {
  color: #153314;
}

@media (max-width: 768px) {
  .recipes-sections-container {
    grid-template-columns: 1fr;
  }
}

/*# sourceMappingURL=index.css.map */
